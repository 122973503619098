<template>
  <div
      v-if="isOpen"
      class="modal fade show"
      aria-labelledby="addNewCardTitle"
      style="display: block; background: #1c1d1c33"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div
                class="d-flex justify-content-between px-1 my-1 align-item-center"
            >
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0"/>

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-12">
                <label class="form-label" for="chartName">Department Name</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="department_name"
                      v-model="formData.department_name"
                      class="form-control add-credit-card-mask"
                      placeholder="Department Name"
                  />
                  <ErrorMessage
                      name="department_name"
                      class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="chartName">Department ID</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="department_id"
                      v-model="formData.department_id"
                      class="form-control add-credit-card-mask"
                      placeholder="Department ID"
                  />
                  <ErrorMessage
                      name="department_id"
                      class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2"/>
              <button
                  :disabled="loading"
                  type="submit"
                  class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
              >
                <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button
                  @click.prevent="toggleModal"
                  class="btn btn-outline-secondary mt-1 waves-effect"
              >
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import objToArray      from "@/services/utils/object-to-array";
import {ref, computed} from "@vue/runtime-core";

const isOpen  = ref(false);
const loading = ref(false);
const emit    = defineEmits(['onSubmitForm', 'onCloseDepartmentModal'])

const props = defineProps({
  formData  : Object,
  showParent: {
    type   : Boolean,
    default: false
  },
  department: Array,
  modalTitle: {
    type   : String,
    default: 'Add Department'
  },
  // submitEvent: {
  //   default: 'onSubmitDepartment'
  // }
});

const schema = {
  department_name: 'required|min:3|max:100',
  department_id  : 'required|min:3|max:100',
};

function submit() {
  emit('onSubmitForm');
}

function toggleModal() {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    emit("onCloseDepartmentModal");
  }
}

defineExpose({
  toggleModal
})
</script>
